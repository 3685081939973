export function PasswordVerification() {
  const passwordModule = document.querySelector('.js-password-module');

  if (passwordModule) {
    const createPasswordGroup = document.querySelector('#group_password1') || document.querySelector('#group_new_password1');
    const confirmPasswordGroup = document.querySelector('#group_password2') || document.querySelector('#group_new_password2');
    const passwordVerification1 = document.querySelector('.password-verification-1');
    const passwordVerification2 = document.querySelector('.password-verification-2');
    const passwordVerification3 = document.querySelector('.password-verification-3');
    const passwordVerification4 = document.querySelector('.password-verification-4');
    const submitButton = document.querySelector('.js-submit-btn');
    const initPassword = createPasswordGroup.querySelector('input');
    const confirmPassword = confirmPasswordGroup.querySelector('input');

    passwordVerification1.checked = false;
    passwordVerification2.checked = false;
    passwordVerification3.checked = false;
    passwordVerification4.checked = false;

    initPassword.addEventListener('input', function (evt) {
      const password = this.value;


      passwordVerification1.checked = !!(password.length >= 8);
      if (passwordVerification1.checked) {
        passwordVerification1.parentElement.classList.add('dark-text');
      } else {
        passwordVerification1.parentElement.classList.remove('dark-text');
      }

      passwordVerification2.checked = !!password.match(/[a-z]/);
      if (passwordVerification2.checked) {
        passwordVerification2.parentElement.classList.add('dark-text');
      } else {
        passwordVerification2.parentElement.classList.remove('dark-text');
      }

      passwordVerification3.checked = !!password.match(/[A-Z]/);
       if (passwordVerification3.checked) {
        passwordVerification3.parentElement.classList.add('dark-text');
      } else {
        passwordVerification3.parentElement.classList.remove('dark-text');
      }

      passwordVerification4.checked = !!password.match(/[`!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~1234567890]/);
       if (passwordVerification4.checked) {
        passwordVerification4.parentElement.classList.add('dark-text');
      } else {
        passwordVerification4.parentElement.classList.remove('dark-text');
      }

      submitButton.disabled = !shouldEnableSubmit();
    });

    // disable submit button if all checks are met
    confirmPasswordGroup.querySelector('input').addEventListener('input', function (evt) {
      submitButton.disabled = !shouldEnableSubmit();
    });

    function shouldEnableSubmit() {
      return !!(passwordVerification1.checked &&
        passwordVerification2.checked &&
        passwordVerification3.checked &&
        passwordVerification4.checked &&
        initPassword.value !== null &&
        confirmPassword.value !== null &&
        initPassword.value == confirmPassword.value);
    }
  }
}




import * as Sentry from "@sentry/browser";
import { BrowserTracing } from "@sentry/tracing";
import { PasswordVerification } from './modules/passwordVerification';
import { setupFormFieldPlaceholders, setUpPasswordInputToggles } from './modules/customFormFields';

const body = document.querySelector('body');
const footerLinks = document.querySelectorAll('.js-footer-section');
const navbar = document.querySelector('.js-navbar');
const navbarCheckbox = document.querySelector('.js-navbar-checkbox');


// handle password verification JS
PasswordVerification();

// handle custom form fields
setupFormFieldPlaceholders();
setUpPasswordInputToggles();




if (navbar && navbarCheckbox) {
  navbarCheckbox.onclick = function () {
    if (!navbar.classList.contains('height-100vh')) {
      navbar.classList.add('height-100vh');
    } else {
      navbar.classList.remove('height-100vh');
    }
  };
}

// handles scroll behavior for navbar
body.addEventListener('scroll', function(e) {
  const top  = body.pageYOffset || body.scrollTop
  if (top == 0) {
    if (!navbar.classList.contains('nav-transparent')) {
      navbar.classList.add('nav-transparent')
    }
  } else {
    if (navbar.classList.contains('nav-transparent')) {
        navbar.classList.remove('nav-transparent');
    }
  }
});


function setTimezoneCookie() {
  const tz = Intl.DateTimeFormat().resolvedOptions().timeZone;
  document.cookie = "browser.timezone=" + tz + ";path=/";
}

setTimezoneCookie()


// handle footer collapse
footerLinks.forEach(function (link) {
  link.addEventListener("click", function () {
    if (!link.parentElement.classList.contains('active')) {
      link.parentElement.classList.add('active');
    } else {
      link.parentElement.classList.remove('active');
    }
  });
});


function getSentryEnv() {
  let sentryEnvironment = "unknown";
  let sentryDSN = "https://6ba2b9644bc84189860f1076993ca74c@o34859.ingest.sentry.io/6399292";
  // This is ugly, but since this is static code running in a browser there aren't a ton of choices.
  // Another idea would be to have the server provide a view which returns the Sentry.BrowserOptions data
  // and this function could just get that. We could then control these settings all in one place and not
  // require a re-deployment to change them. The down side is that we slow down initial page load
  // and introduce more moving parts to go wrong before sentry is connected to record it for us.
  if (typeof window !== 'undefined') {
    const hostName = window.location.host;
    if (hostName === 'ocr-plus.io' || hostName === 'www.ocr-plus.io') {
      sentryEnvironment = 'production';
    } else if (hostName === 'staging.ocr-plus.io') {
      sentryEnvironment = 'staging';
    } else if (hostName === 'dev.ocr-plus.io') {
      sentryEnvironment = 'dev';
    } else if (hostName.startsWith('localhost')) {
      sentryEnvironment = 'local';
      sentryDSN = "";
    }
  }

  return {
    dsn: sentryDSN,
    environment: sentryEnvironment,
    integrations: [new BrowserTracing()],
    tracesSampleRate: 0.2,
  }
}

Sentry.init(getSentryEnv());

export function setupFormFieldPlaceholders() {
  // are we going to just do this for all form inputs? Then why even have this wrapper?
  const customFormFieldModule = document.querySelector('.js-custom-form-field-module');

  if (customFormFieldModule) {
    const inputs = document.querySelectorAll('.form-group > .sc-searchbox > input');

    // adds utility class to inputs
    inputs.forEach(input => {
      input.addEventListener('input', function (evt) {
        if (this.value.length >= 1) {
          input.parentElement.querySelector('.js-fake-placeholder').classList.add('hidden');
        } else {
          if (input.parentElement.querySelector('.js-fake-placeholder').classList.contains('hidden')) {
            input.parentElement.querySelector('.js-fake-placeholder').classList.remove('hidden');
          }
        }
      });
    });
  }
}

export function setUpPasswordInputToggles() {
  // We can see that really this is two separate pieces of functionality and so arguably these belong in separate modules and functions
  // handle password-reveal
  const passwordToggles = document.querySelectorAll(".js-password-icon");

  passwordToggles.forEach(passwordToggle => {
    const passwordInput = document.querySelector(passwordToggle.dataset['target']);
    passwordToggle.addEventListener("click", function () {
      // toggle the type attribute
      const type = passwordInput.getAttribute("type") === "password" ? "text" : "password";
      passwordInput.setAttribute("type", type);
      // toggle the icon
      this.classList.toggle("opacity-02");
    });
  });
}
